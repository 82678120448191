.vicExtensionIcon {
  background-image: url('../img/favicon.png');
  background-size: 24px;
  background-repeat: no-repeat;
  background-position: center;
}

#vicHighlightspoolBtn {
  padding-top: 20px;
}
