.vicExtensionIcon {
  background-image: url('favicon.70b66a2ca739fc7591da.png');
  background-size: 24px;
  background-repeat: no-repeat;
  background-position: center;
}

#vicHighlightspoolBtn {
  padding-top: 20px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi4uLy4uLy4uLy4uLy4uLy4uLy4uLy4uL3ZpYy1leHRlbnNpb25zLXN0eWxlcy5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0UseURBQUE7RUFDQSxxQkFBQTtFQUNBLDRCQUFBO0VBQ0EsMkJBQUE7QUFDRjs7QUFFQTtFQUNFLGlCQUFBO0FBQ0YiLCJmaWxlIjoidmljLWV4dGVuc2lvbnMtc3R5bGVzLnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyIudmljRXh0ZW5zaW9uSWNvbiB7XG4gIGJhY2tncm91bmQtaW1hZ2U6IHVybCgnLi4vaW1nL2Zhdmljb24ucG5nJyk7XG4gIGJhY2tncm91bmQtc2l6ZTogMjRweDtcbiAgYmFja2dyb3VuZC1yZXBlYXQ6IG5vLXJlcGVhdDtcbiAgYmFja2dyb3VuZC1wb3NpdGlvbjogY2VudGVyO1xufVxuXG4jdmljSGlnaGxpZ2h0c3Bvb2xCdG4ge1xuICBwYWRkaW5nLXRvcDogMjBweDtcbn1cbiJdfQ== */